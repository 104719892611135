import { createContext } from "react";

export type InitialValues = {
  isFetching: boolean;
  isAuthenticated: boolean;
  user: string | undefined;
};

interface AuthenticationContextProps extends InitialValues {
  resetPassword: (token: string, plainPassword: string) => Promise<void>;
  checkPasswordResetToken: (token: string) => Promise<string>;
  requestPasswordReset: (emailAddress: string) => Promise<void>;
  loginUser: (emailAddress: string, password: string) => Promise<void>;
  loginImpersonatingUser: (emailAddress: string) => Promise<void>;
  logoutUser: () => Promise<void>;
}

const AuthenticationContext = createContext<AuthenticationContextProps>({
  isFetching: false,
  isAuthenticated: false,
  user: undefined,
  loginUser: () => Promise.reject(),
  loginImpersonatingUser: () => Promise.reject(),
  logoutUser: () => Promise.reject(),
  requestPasswordReset: () => Promise.reject(),
  checkPasswordResetToken: () => Promise.reject(),
  resetPassword: () => Promise.reject(),
});

export default AuthenticationContext;

import * as Sentry from "@sentry/react";
import { Flex, Spin } from "antd";
import { useEffect, useState } from "react";
import * as React from "react";

import Constants from "@/constants";
import { useClientContext } from "@/urql/ClientProvider";

import AuthenticationContext, { InitialValues } from "./context";
import { clearUserTokens, loadWhoami, setMimickingUser } from "./token-storage";

export default function AuthenticationContextProvider({ children }: { children: React.ReactNode }) {
  const [isBooting, setIsBooting] = useState(true);
  const [state, setState] = useState<InitialValues>({ isFetching: false, isAuthenticated: false, user: undefined });
  const clientContext = useClientContext();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const _switch_user = searchParams.get("_switch_user");

    (async function main() {
      const response = await loadWhoami();

      // no active session known
      if (response === undefined) {
        setIsBooting(false);

        return;
      }

      // user is logged in as employee
      // either the user is trying to mimick a customer (identified from the query params)
      // or else they're incorrectly logged in as employee and need to log out first
      if (response.user.groups.includes("GROUP_EMPLOYEES")) {
        // impersonate user
        if (null !== _switch_user && !state.isAuthenticated) {
          const switch_user = decodeURIComponent(_switch_user);

          setMimickingUser(switch_user);
          handleOnLoginImpersonating(switch_user);
          setIsBooting(false);
        } else {
          const errorMessage = `Je hebt een actieve sessie als medewerker en kan niet inloggen in het klantportaal. Log eerst uit bij het medewerkersportaal om te kunnen inloggen als klant.`;
          alert(errorMessage);
        }
      } else {
        // user is logged in.
        setState(current => ({ ...current, isAuthenticated: true, user: response.user.email }));
        setIsBooting(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (state.isAuthenticated) {
      Sentry.setUser({ email: state.user });
    }
  }, [state.isAuthenticated]);

  const handleOnResetPassword = async (token: string, plainPassword: string) => {
    setState(state => ({
      ...state,
      isFetching: true,
    }));

    try {
      const response = await fetch(Constants.backendUrl + "/users/reset-password", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `_confirmationToken=${encodeURIComponent(token)}&_password=${encodeURIComponent(plainPassword)}`,
      });

      if (!response.ok) throw new Error("Could not reset password");
    } finally {
      setState(state => ({
        ...state,
        isFetching: false,
      }));
    }
  };

  const handleOnCheckPasswordResetToken = async (confirmationToken: string) => {
    setState(state => ({
      ...state,
      isFetching: true,
    }));

    try {
      const response = await fetch(Constants.backendUrl + "/users/confirmation-token?v=" + confirmationToken);
      if (!response.ok) throw new Error("Could not find user for token");

      const content = (await response.json()) as { user: { email: string } };
      if (!content.user?.email) throw new Error("Could not find user for token");

      return content.user.email;
    } finally {
      setState(state => ({
        ...state,
        isFetching: false,
      }));
    }
  };

  const handleOnRequestPasswordReset = async (emailAddress: string) => {
    setState(state => ({
      ...state,
      isFetching: true,
    }));

    try {
      const response = await fetch(Constants.backendUrl + "/users/request-password-reset", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `_username=${encodeURIComponent(emailAddress)}`,
      });

      if (!response.ok) throw new Error("Could not request new password");
    } finally {
      setState(state => ({
        ...state,
        isFetching: false,
      }));
    }
  };

  const handleOnLogin = async (emailAddress: string, password: string) => {
    setState(state => ({
      ...state,
      isFetching: true,
      isAuthenticated: false,
      user: emailAddress,
    }));

    try {
      const response = await fetch(Constants.backendUrl + "/users/login_check", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `_username=${encodeURIComponent(emailAddress)}&_password=${encodeURIComponent(password)}`,
        credentials: "include",
      });

      const content = (await response.json()) as { token: string; refresh_token: string } | { code: number; message: string };
      if ("code" in content && content.code === 401) throw new Error(content.message);

      if (!content.email) throw new Error("Could not obtain access token");
      if (content.is_emp) throw new Error("You are an employee");

      localStorage.setItem("userEmail", content.email);
      localStorage.setItem("userId", content.user_id);

      setState(state => ({
        ...state,
        isAuthenticated: true,
        isFetching: false,
        user: content.email,
      }));
    } catch (error) {
      setState(state => ({
        ...state,
        isAuthenticated: false,
        isFetching: false,
        user: undefined,
      }));

      throw error;
    }
  };

  const handleOnLoginImpersonating = async (emailAddress: string) => {
    setState(state => ({
      ...state,
      isAuthenticated: true,
      user: emailAddress,
    }));
  };

  const handleOnLogout = async () => {
    try {
      await fetch(Constants.backendUrl + "/users/logout", {
        credentials: "include",
      });
    } catch (_error) {
      // ..
    }

    clearUserTokens();
    clientContext.resetClient();

    setState(state => ({
      ...state,
      isAuthenticated: false,
      isFetching: false,
      user: undefined,
    }));
  };

  const authContextValue = React.useMemo(
    () => ({
      ...state,
      loginUser: handleOnLogin,
      loginImpersonatingUser: handleOnLoginImpersonating,
      logoutUser: handleOnLogout,
      requestPasswordReset: handleOnRequestPasswordReset,
      checkPasswordResetToken: handleOnCheckPasswordResetToken,
      resetPassword: handleOnResetPassword,
    }),
    [state]
  );

  return (
    <AuthenticationContext.Provider value={authContextValue}>
      {isBooting ? (
        <Flex align="center" justify="center" style={{ height: "100vh", width: "100vw" }}>
          <Spin />
        </Flex>
      ) : (
        children
      )}
    </AuthenticationContext.Provider>
  );
}

import "leaflet/dist/leaflet.css";

import { Card } from "antd";
import * as Leaflet from "leaflet/dist/leaflet";
import * as React from "react";

import LocationPicker from "@/components/LocationPicker";

import pngDefaultIcon from "./marker-icon-blue.png";
import pngActiveIcon from "./marker-icon-gold.png";

interface LocationsCardProps {
  activeLocationId: string | undefined;
  loading: boolean;
  locations: { id: string; name: string; address: { coordinates: { latitude: number; longitude: number } } }[];
  onClick: (locationId: string) => void;
}

const defaultIcon = new Leaflet.Icon({
  iconUrl: pngDefaultIcon,
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const activeIcon = new Leaflet.Icon({
  iconUrl: pngActiveIcon,
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export default function LocationsCard({ activeLocationId, loading, locations, onClick }: LocationsCardProps) {
  const mapContainer = React.useRef<HTMLDivElement>(null);
  const markerMap = React.useRef<Record<string, Leaflet.Marker>>({});
  const leafletInstance = React.useRef<Leaflet.Map>();

  // clean up map on unmount
  React.useEffect(() => {
    return function () {
      leafletInstance.current?.remove();
      leafletInstance.current = undefined;
    };
  }, []);

  // add location markers and center
  React.useEffect(() => {
    if (!mapContainer.current) return;

    if (!leafletInstance.current) {
      leafletInstance.current = Leaflet.map(mapContainer.current)
        .setView([51.505, -0.09], 13)
        .addLayer(
          Leaflet.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          })
        );
    }

    markerMap.current = Object.fromEntries(
      locations.map(location => {
        // eslint-disable-next-line prettier/prettier
        const coordinates: Leaflet.LatLngExpression = [
          location.address.coordinates.latitude,
          location.address.coordinates.longitude
        ];

        const current = Leaflet.marker(coordinates, {
          autoPan: true,
          riseOnHover: true,
          title: location.name,
          icon: defaultIcon,
        })
          .addEventListener("click", event => {
            const { [location.id]: _, ...restMarkers } = markerMap.current;
            Object.values(restMarkers).forEach(marker => marker.setIcon(defaultIcon));

            event.target.setIcon(activeIcon);
            onClick(location.id);
          })
          .addTo(leafletInstance.current as Leaflet.Map);

        return [location.id, current] as const;
      })
    );

    const mapBounds = new Leaflet.LatLngBounds(locations.map(l => [l.address.coordinates.latitude, l.address.coordinates.longitude]));

    if (mapBounds.isValid()) {
      leafletInstance.current?.fitBounds(mapBounds);
      leafletInstance.current?.setZoom(leafletInstance.current?.getZoom() - 1);
    }

    return function () {
      leafletInstance.current?.clearAllEventListeners();
      Object.values(markerMap.current).forEach(marker => marker.remove());

      markerMap.current = {};
    };
  }, [locations]);

  return (
    <Card
      actions={["Klik of selecteer een locatie voor meer gegevens"]}
      bodyStyle={{ padding: 0 }}
      extra={<LocationPicker onChange={locationId => onClick(locationId)} style={{ minWidth: 300 }} value={activeLocationId} />}
      loading={loading}
      title={`Locaties (${locations.length})`}
    >
      <div ref={mapContainer} style={{ height: 300 }} />
    </Card>
  );
}

import { Typography } from "antd";
import { useMemo } from "react";

interface CommentProps {
  author?: string;
  avatar: React.ReactNode;
  content: React.ReactNode;
  datetime?: string;
}

export default function Comment({ author, avatar, content, datetime }: CommentProps) {
  const parsedContent = useMemo(
    () => (typeof content === "string" ? <div dangerouslySetInnerHTML={{ __html: content }} /> : content),
    [content]
  );

  return (
    <div style={{ display: "flex", flexDirection: "row", paddingBlock: 16 }}>
      {avatar}
      <div style={{ display: "flex", flexDirection: "column", flex: 1, marginLeft: 12 }}>
        {(undefined !== author || undefined !== datetime) && (
          <div style={{ marginBottom: 4 }}>
            <Typography.Text style={{ fontSize: 12 }}>{author} </Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {datetime}
            </Typography.Text>
          </div>
        )}
        {parsedContent}
      </div>
    </div>
  );
}

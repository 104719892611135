import { Divider, Space, TreeSelect, TreeSelectProps } from "antd";
import dayjs from "dayjs";
import { gql, useQuery } from "urql";

import formatProductDescription from "@/functions/format-product-description";

interface RelatedProductsSelector<ValueType> extends TreeSelectProps<ValueType> {
  locationId: string;
}

function formatProductNodeTitle(product: any) {
  return (
    <>
      <div>{formatProductDescription(product)}</div>
      {(null !== product.serialCode || null !== product.productionBatch || null !== product.replacementDate) && (
        <Space direction="horizontal" size={4} split={<Divider type="vertical" />}>
          {null !== product.serialCode && <span>Serienummer: {product.serialCode}</span>}
          {null !== product.productionBatch && <span>Productiebatch: {product.productionBatch}</span>}
          {null !== product.replacementDate && <span>Vervangdatum: {dayjs(product.replacementDate).format("MM/yyyy")}</span>}
        </Space>
      )}
    </>
  );
}

export default function RelatedProductsSelector<ValueType>({ locationId, ...restProps }: RelatedProductsSelector<ValueType>) {
  const [{ data, fetching: loading }] = useQuery({
    query: ServicedProductsQuery,
    variables: {
      locationId,
    },
  });

  return (
    <TreeSelect loading={loading} {...restProps}>
      {(data?.servicedProducts.edges ?? []).map(product => {
        return <TreeSelect.TreeNode key={product.id} value={product.id} title={formatProductNodeTitle(product)} />;
      })}
    </TreeSelect>
  );
}

const ServicedProductsQuery = gql`
  query ($locationId: ID!) {
    servicedProducts(locationId: $locationId) {
      totalCount
      edges {
        id
        productType {
          id
          code
          description
        }
        brand
        optionalDescription
        serialCode
        expirationDate
        productionBatch
        locationDescription
        replacementDate
        condition
      }
    }
  }
`;

import { SendOutlined } from "@ant-design/icons";
import { App, Button, Form, Input } from "antd";
import * as React from "react";

interface CommentEditorProps {
  onSubmit: (value: string) => Promise<void>;
  submitting: boolean;
}

export default function CommentEditor({ onSubmit, submitting }: CommentEditorProps) {
  const { notification } = App.useApp();
  const [value, setValue] = React.useState("");

  const handleOnSubmit = async () => {
    try {
      await onSubmit(value);
      setValue("");
    } catch (error) {
      notification.error({
        message: "Er ging iets mis tijdens het toevoegeven van uw opmerking. Stuur een e-mail naar service@defibrion.nl",
      });
    }
  };

  return (
    <>
      <Form.Item>
        <Input.TextArea rows={3} onChange={e => setValue(e.target.value)} value={value} />
      </Form.Item>
      <Button loading={submitting} onClick={() => handleOnSubmit()} icon={<SendOutlined />} type="primary">
        Opmerking plaatsen
      </Button>
    </>
  );
}

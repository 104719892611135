interface SortOption {
  text: string | number;
}

export default function tableSorter(a: SortOption, b: SortOption) {
  if (typeof a.text !== "string" || typeof b.text !== "string") return 0;
  if (a === b) return 0;

  return a.text.toLocaleLowerCase() < b.text.toLocaleLowerCase() ? -1 : 1;
}

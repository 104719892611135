import * as React from "react";

import IssueModal from "./IssueModal";

interface ShowIssueModalProps {
  onClose: () => void;
  issueId: string;
}

export default function ShowIssueModal({ onClose, issueId }: ShowIssueModalProps) {
  return <IssueModal onClose={onClose} issueId={issueId} />;
}

const environment = process.env.NODE_ENV === "production" ? "prod" : "dev";

const Constants = {
  appName: "Savvy",
  backendUrl: environment === "prod" ? "https://api.defibrion.nl" : "http://localhost",
  clientPortalUrl: environment === "prod" ? "https://mijn.defibrion.nl" : "http://localhost:5174",
  environment,
  groupIds: {
    GROUP_EMPLOYEES: "GROUP_EMPLOYEES",
    GROUP_SERVICE_EMPLOYEES: "GROUP_SERVICE_EMPLOYEES",
  },
};

export default Constants;

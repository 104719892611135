import { Select, SelectProps } from "antd";
import * as React from "react";
import { gql, useQuery } from "urql";

import formatAddress from "@/functions/format-address";

interface LocationPickerProps extends SelectProps<string> {
  autoSelectDefault?: boolean;
  includeDeleted?: boolean;
}

function mapSingleLocation(location: RelationProps["locations"][0], deletedSection = false) {
  return (
    <Select.Option key={location.id} value={location.id} title={location.name} style={{ color: deletedSection ? "red" : undefined }}>
      <div>{location.name}</div>
      <small>{formatAddress(location.address)}</small>
    </Select.Option>
  );
}

function mapAllLocations(locations: RelationProps["locations"], deletedSection = false) {
  return [...locations].sort((a, b) => a.name.localeCompare(b.name)).map(l => mapSingleLocation(l, deletedSection));
}

export default function LocationPicker({ autoSelectDefault, onChange, includeDeleted, value, ...restProps }: LocationPickerProps) {
  const [{ data, fetching: loading }] = useQuery<{ relation: RelationProps }>({
    query: LocationsQuery,
    requestPolicy: "cache-first",
  });

  const handleFilterOption = React.useCallback((inputValue: string, option: any) => {
    // is a category
    if (option.options) return false;

    const searchQuery = inputValue.toLocaleLowerCase();
    return (
      option.title.toLocaleLowerCase().includes(searchQuery) ||
      option.children?.[1]?.props.children.toLocaleLowerCase().includes(searchQuery)
    );
  }, []);

  const values = React.useMemo(() => {
    const locations = data?.relation.locations ?? [];
    const { activeLocations, deletedLocations } = locations.reduce(
      (accumulator, current) => {
        if (current.deletedAt === null) {
          accumulator.activeLocations.push(current);
        } else {
          accumulator.deletedLocations.push(current);
        }

        return accumulator;
      },
      { activeLocations: [], deletedLocations: [] }
    );

    if (!includeDeleted || deletedLocations.length < 1) return mapAllLocations(activeLocations, false);

    return [
      <Select.OptGroup key="active-locations" label="Actieve locaties">
        {mapAllLocations(activeLocations, false)}
      </Select.OptGroup>,
      <Select.OptGroup key="deleted-locations" label="Verwijderde locaties">
        {mapAllLocations(deletedLocations, true)}
      </Select.OptGroup>,
    ];
  }, [data]);

  React.useEffect(() => {
    if (!autoSelectDefault || value) return;
    if (undefined === data || data.relation.locations.length < 1) return;

    const initialValue = [...data.relation.locations]
      .filter(location => location.deletedAt === null)
      .sort((a, b) => a.name.localeCompare(b.name))
      .shift();

    if (undefined === initialValue) return;

    onChange?.(initialValue.id, {
      label: mapSingleLocation(initialValue, false),
      value: initialValue.id,
    });
  }, [data, value]);

  return (
    <Select
      {...restProps}
      onChange={onChange}
      filterOption={handleFilterOption}
      loading={loading}
      optionFilterProp="children"
      optionLabelProp="title"
      placeholder="Selecteer locatie"
      showSearch
      value={value}
    >
      {values}
    </Select>
  );
}

interface RelationProps {
  id: string;
  locations: {
    id: string;
    name: string;
    deletedAt: string | null;
    address: { street: string; postalCode: string; city: string; country: string };
  }[];
}

const LocationsQuery = gql`
  query {
    relation {
      id
      locations {
        id
        name
        deletedAt
        address {
          street
          postalCode
          city
          country
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`;

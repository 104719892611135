import { Skeleton } from "antd";

import useRelationProfile from "@/hooks/use-relation-profile";

import CustomerDashboard from "./CustomerDashboard";
import DealerDashboard from "./DealerDashboard";

export default function DashboardTrampoline() {
  const { isDealer } = useRelationProfile();

  if (isDealer === undefined) {
    return <Skeleton />;
  }

  return isDealer ? <DealerDashboard /> : <CustomerDashboard />;
}

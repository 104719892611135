import { retryExchange, RetryExchangeOptions } from "@urql/exchange-retry";
import { cacheExchange, Client, fetchExchange } from "urql";

import { isMimickingUser, refreshSession, resetUserSession } from "@/authorization/token-storage";
import Constants from "@/constants";

let refreshInFlight = false;
let refreshDidFail = false;

const retryOptions: RetryExchangeOptions = {
  initialDelayMs: 1000,
  maxDelayMs: 15000,
  randomDelay: true,
  maxNumberAttempts: 2,
  retryIf: error => {
    const isCredError = error.toString().includes("401") || error.toString().includes("403") || error.toString().includes("Unauthorized");

    if (!refreshInFlight && !refreshDidFail && isCredError) {
      refreshInFlight = true;

      refreshSession()
        .then(succeeded => {
          refreshInFlight = false;
          refreshDidFail = !succeeded;

          if (false === succeeded) resetUserSession();
        })
        .catch(() => {
          refreshInFlight = false;
          refreshDidFail = true;

          resetUserSession();
        });
    }

    // only re-attempt if the error was because the token is expired.
    return !refreshDidFail && isCredError;
  },
};

export default function configureClient() {
  return new Client({
    url: Constants.backendUrl + "/graphql/customers",
    exchanges: [cacheExchange, retryExchange(retryOptions), fetchExchange],
    fetchOptions: () => {
      const mimickingUser = isMimickingUser();

      if (undefined === mimickingUser) {
        return { credentials: "include" };
      }

      return {
        credentials: "include",
        headers: {
          "X-Switch-User": mimickingUser,
        },
      };
    },
  });
}

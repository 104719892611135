import { Card, Col, Row, Space, Table } from "antd";
import dayjs from "dayjs";

import PhoneNumber from "@/components/PhoneNumber";
import formatAddress from "@/functions/format-address";
import useRelationProfile from "@/hooks/use-relation-profile";

import InfoProp from "./InfoProp";

export default function DealerDashboard() {
  const { loading, relation, relationList, locationMap } = useRelationProfile();

  return (
    <Space direction="vertical" size={16}>
      <Row gutter={32}>
        <Col span={12}>
          <Card title="Hartelijk welkom op het klantportaal van Defibrion!">
            <p>
              Hier vindt u alle informatie rondom uw onderhoudscontracten bij Defibrion. Informatie over de planning van de bezoekafspraken,
              de status en gegevens van alle veiligheidsmiddelen en de onderhoudsrapportages. Via de berichtenmodule kunt u communiceren met
              de serviceafdeling.
            </p>

            <p>
              De helpdesk is telefonische te bereiken op: <PhoneNumber>050 3116085</PhoneNumber> (voor Nederland) en{" "}
              <PhoneNumber>037 470 079</PhoneNumber> (voor België)
            </p>
          </Card>
        </Col>
        <Col span={12}>
          <Card loading={loading} title="Klantgegevens">
            {undefined !== relation && (
              <Row justify="space-between" gutter={4}>
                <Col span={24 / 2}>
                  <InfoProp label="Naam" value={relation.name} />
                  <InfoProp label="Vestigingslocatie" value={relation.place} />
                </Col>
                <Col span={24 / 2}>
                  <InfoProp label="Klantcode" value={relation.afasCode} />
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
      <Table
        bordered
        caption={
          <div style={{ marginBlock: 12, display: "flex", marginInline: 12 }}>
            Hieronder een overzicht van alle klanten waarvoor u een onderhoudscontract heeft bij Defibrion
          </div>
        }
        // caption="Hieronder een overzicht van alle klanten waarvoor u een onderhoudscontract heeft bij Defibrion"
        columns={[
          { title: "Klantnummer", dataIndex: "afasCode" },
          { title: "Klantnaam", dataIndex: "name" },
          {
            title: "Aanvangsdatum contract",
            dataIndex: ["activeContract", "startingOn"],
            render: startingOn => (startingOn ? dayjs(startingOn).format("DD-MM-YYYY") : "-"),
          },
          {
            title: "Einddatum contract",
            dataIndex: ["activeContract", "endingOn"],
            render: endingOn => (endingOn ? dayjs(endingOn).format("DD-MM-YYYY") : "-"),
          },
        ]}
        dataSource={relationList.slice(1)}
        expandable={{
          expandedRowRender: record => {
            const locations = locationMap[record.id].locations;

            return (
              <Table
                bordered
                columns={[
                  { title: "Naam", dataIndex: ["name"], sorter: (a, b) => b.name.localeCompare(a.name), defaultSortOrder: "descend" },
                  { title: "Contactpersoon", dataIndex: ["contactPerson"] },
                  { title: "Adres", dataIndex: ["address"], render: address => formatAddress(address) },
                  {
                    title: "Telefoonnummer",
                    key: "phoneNumber",
                    render: location =>
                      [location.phoneNumber, location.mobilePhoneNumber]
                        .filter(value => value !== null && value.length > 0)
                        .map(element => <div key={element}>{element}</div>),
                  },
                  {
                    title: "E-mail adres",
                    key: "emailAddress",
                    render: location =>
                      [location.primaryEmail, location.secondaryEmail]
                        .filter(value => value !== null && value.length > 0)
                        .map(element => <div key={element}>{element}</div>),
                  },
                ]}
                dataSource={locations}
                style={{ marginBlock: 16, marginRight: 12 }}
                pagination={locations.length > 10 ? undefined : false}
              />
            );
          },
        }}
        loading={loading}
        rowKey={record => record.id}
      />
    </Space>
  );
}

import { App, Button, ButtonProps } from "antd";
import * as React from "react";

export default function DownloadButton({ onClick, ...restProps }: Omit<ButtonProps, "onClick"> & { onClick: () => Promise<void> }) {
  const { notification } = App.useApp();
  const [loading, setLoading] = React.useState(false);

  const handleOnClick = async () => {
    setLoading(true);

    onClick()
      .then(() => setLoading(false))
      .catch(error => {
        notification.error({ description: error.message, message: "Woops! Kon rapportage niet downloaden" });
        setLoading(false);
      });
  };

  return <Button {...restProps} loading={loading} onClick={handleOnClick} />;
}

import { createContext, useContext, useState } from "react";
import { Provider as UrqlProvider } from "urql";

import configureClient from "./configure-client";

const ClientContext = createContext({
  resetClient: () => {
    // ..
  },
});

interface ClientProviderProps {
  children: React.ReactNode;
}

export function ClientProvider({ children }: ClientProviderProps) {
  const [client, setClient] = useState(() => configureClient());

  return (
    <ClientContext.Provider value={{ resetClient: () => setClient(configureClient()) }}>
      <UrqlProvider value={client}>{children}</UrqlProvider>
    </ClientContext.Provider>
  );
}

export function useClientContext() {
  return useContext(ClientContext);
}

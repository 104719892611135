import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Space } from "antd";
import dayjs from "dayjs";
import * as React from "react";
import { gql, useQuery } from "urql";

import PhoneNumber from "@/components/PhoneNumber";
import formatAddress from "@/functions/format-address";

import InfoProp from "./InfoProp";
import LocationsCard from "./LocationsCard";
import UpdateLocationModal from "./UpdateLocationModal";

export default function CustomerDashboard() {
  const [{ data, fetching: loading }, refetch] = useQuery({ query: RelationQuery });

  const [activeLocationId, setActiveLocationId] = React.useState<string | undefined>();
  const [updateLocationModalVisible, setUpdateLocationModalVisible] = React.useState(false);

  React.useEffect(() => {
    if (undefined !== activeLocationId || undefined === data) return;
    setActiveLocationId(data.relation.locations[0].id);
  }, [data]);

  const handleOnLocationClick = (locationId: string) => {
    setActiveLocationId(locationId);
  };

  const handleOnEditClick = () => {
    setUpdateLocationModalVisible(true);
  };

  const relation = data?.relation;
  const contract = data?.contract;

  // eslint-disable-next-line prettier/prettier
  const currentLocation = undefined !== activeLocationId
    ? data?.relation.locations.find(l => l.id === activeLocationId)
    : undefined;

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridTemplateRows: "masonry", columnGap: 16, rowGap: 16 }}>
        <Space direction="vertical" size={16}>
          <Card title="Hartelijk welkom op het klantportaal van Defibrion!">
            <p>
              Hier vindt u alle informatie rondom uw veiligheidsmiddelen die in onderhoud zijn bij Defibrion. Informatie over de planning
              van de bezoekafspraken, de status en gegevens van alle veiligheidsmiddelen en de onderhoudsrapportages. Via de berichtenmodule
              kunt u communiceren met de serviceafdeling.
            </p>

            <p>
              De helpdesk is telefonische te bereiken op: <PhoneNumber>050 3116085</PhoneNumber> (voor Nederland) en{" "}
              <PhoneNumber>037 470 079</PhoneNumber> (voor België)
            </p>
          </Card>
          <Card loading={loading} title="Klantgegevens">
            {undefined !== relation && (
              <Row justify="space-between" gutter={4}>
                <Col span={24 / 3}>
                  <InfoProp label="Naam" value={relation.name} />
                  <InfoProp label="Vestigingslocatie" value={relation.place} />
                </Col>
                <Col span={24 / 3}>
                  <InfoProp label="Klantcode" value={relation.afasCode} />
                  <InfoProp label="Aantal locaties" value={relation.locations.length} />
                </Col>
                <Col span={24 / 3}>
                  <InfoProp label="Startdatum contract" value={contract?.startingOn ? dayjs(contract.startingOn).format("MM/YYYY") : "-"} />
                  <InfoProp label="Einddatum contract" value={contract?.endingOn ? dayjs(contract.endingOn).format("MM/YYYY") : "-"} />
                </Col>
              </Row>
            )}
          </Card>
        </Space>
        <Space direction="vertical" size={16}>
          <LocationsCard
            activeLocationId={activeLocationId}
            loading={loading}
            locations={relation?.locations ?? []}
            onClick={handleOnLocationClick}
          />
          {undefined !== currentLocation && (
            <Card
              actions={[
                <Button key="edit-button" onClick={() => handleOnEditClick()} icon={<EditOutlined />} type="text">
                  Contactgegevens aanpassen
                </Button>,
              ]}
              title={`Geselecteerde locatie: ${currentLocation.name}`}
            >
              <Row justify="space-between" gutter={4}>
                <Col span={24 / 2}>
                  <InfoProp label="Contactpersoon" value={currentLocation.contactPerson} />
                  <InfoProp label="Primair e-mail adres" value={currentLocation.primaryEmail ?? "-"} />
                  <InfoProp label="Secondair e-mail adres" value={currentLocation.secondaryEmail ?? "-"} />
                  <InfoProp label="Adres" value={formatAddress(currentLocation.address)} />
                </Col>
                <Col span={24 / 2}>
                  <InfoProp label="Telefoonnummer" value={currentLocation.phoneNumber ?? "-"} />
                  <InfoProp label="Mobiel telefoonnummer" value={currentLocation.mobilePhoneNumber ?? "-"} />
                  <InfoProp label="Taalinstelling" value={locales[currentLocation.locale as keyof typeof locales]} />
                </Col>
              </Row>
            </Card>
          )}
        </Space>
      </div>
      {updateLocationModalVisible && undefined !== currentLocation && (
        <UpdateLocationModal
          onClose={() => {
            setUpdateLocationModalVisible(false);
            refetch();
          }}
          location={currentLocation}
        />
      )}
    </>
  );
}

const locales = {
  nl_NL: "Nederlands (Nederland)",
  nl_BE: "Nederlands (Belgie)",
  fr_BE: "Frans (Belgie)",
};

const RelationQuery = gql`
  query {
    relation {
      id
      name
      place
      afasCode
      isDealer
      locations {
        id
        name
        contactPerson
        primaryEmail
        secondaryEmail
        phoneNumber
        mobilePhoneNumber
        locale
        address {
          street
          postalCode
          city
          country
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
    contract {
      id
      startingOn
      endingOn
    }
  }
`;

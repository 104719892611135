import Constants from "@/constants";

let mimickingUser: string | undefined = undefined;
export function isMimickingUser() {
  return mimickingUser;
}

export function setMimickingUser(emailAddress: string) {
  mimickingUser = emailAddress;
}

interface WhoamiResponse {
  user: {
    id: number;
    email: string;
    locale: string;
    groups: string[];
  };
}

export async function loadWhoami() {
  const response = await fetch(Constants.backendUrl + "/users/whoami", {
    method: "POST",
    credentials: "include",
  });

  if (response.status !== 200) {
    return undefined;
  }

  return response.json() as Promise<WhoamiResponse>;
}

export async function refreshSession() {
  const response = await fetch(Constants.backendUrl + "/users/refresh_check", {
    method: "POST",
    credentials: "include",
  });

  return response.ok;
}

export function resetUserSession() {
  clearUserTokens();
  window.location.reload();
}

export function clearUserTokens() {
  localStorage.removeItem("refreshJwtToken");
  localStorage.removeItem("userJwtToken");
  localStorage.removeItem("userId");
}

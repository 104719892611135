import { LoadingOutlined, LogoutOutlined } from "@ant-design/icons";
import { Layout, Menu, Space, Typography } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useState } from "react";
import * as React from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { isMimickingUser } from "@/authorization/token-storage";
import useAuthState from "@/authorization/use-auth-state";
import routes from "@/routes";

import LogoImagePng from "../../../assets/logo-extended.png";
import MaybeMimickingUserBanner from "./MaybeMimickingUserBanner";

export default function RouterLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedKey = location.pathname.length === 1 ? location.pathname : location.pathname.substring(1);

  const { user, logoutUser } = useAuthState();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const isMimickingUser_ = isMimickingUser() !== undefined;

  const menuItems: ItemType[] = React.useMemo(
    () =>
      routes.map(route => ({
        icon: route.icon,
        label: <Link to={route.path}>{route.title}</Link>,
        key: route.path,
      })),
    []
  );

  const handleOnMenuClick: React.ComponentProps<typeof Menu>["onClick"] = ({ key }) => {
    navigate(key);
  };

  const handleOnLogout = async () => {
    setIsLoggingOut(true);
    logoutUser();
  };

  return (
    <>
      <MaybeMimickingUserBanner />
      <Layout>
        <Layout.Header
          style={{
            alignItems: "center",
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            height: 75,
            background: "linear-gradient(45deg, rgba(28, 159, 212, 1) 0%, rgba(25, 81, 160, 1) 35%)",
          }}
        >
          <div style={{ alignItems: "center", display: "flex", justifyContent: "center", width: 225 }}>
            <img src={LogoImagePng} style={{ width: 100 }} />
          </div>
          <Space direction="horizontal">
            <Typography.Text style={{ color: "white" }}>{user}</Typography.Text>
            {!isMimickingUser_ && (
              <>
                {!isLoggingOut ? (
                  <LogoutOutlined onClick={() => handleOnLogout()} style={{ color: "white", height: 24, width: 24 }} />
                ) : (
                  <LoadingOutlined style={{ color: "white", height: 24, width: 24 }} />
                )}
              </>
            )}
          </Space>
        </Layout.Header>
        <Layout.Content style={{ padding: "16px 50px" }}>
          <Layout style={{ backgroundColor: "white", padding: "24px 0" }}>
            <Layout.Sider style={{ backgroundColor: "white" }} width={225}>
              <Menu mode="inline" onClick={handleOnMenuClick} selectedKeys={[selectedKey]} items={menuItems} style={{ height: "100%" }} />
            </Layout.Sider>
            <Layout.Content style={{ padding: "0 24px", minHeight: window.innerHeight - 227 }}>
              <Outlet />
            </Layout.Content>
          </Layout>
        </Layout.Content>
        <Layout.Footer style={{ textAlign: "center" }}>{`Defibrion B.V. © ${new Date().getUTCFullYear()} `}</Layout.Footer>
      </Layout>
    </>
  );
}

import { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import AuthenticationContext from "@/authorization/context";
import RouterLayout from "@/containers/Layout";
import Login from "@/containers/NotAuthenticated/Login";
import ResetPassword from "@/containers/NotAuthenticated/ResetPassword";

import routes from "./routes";

export default function DefibrionApp() {
  const { isAuthenticated } = useContext(AuthenticationContext);

  return (
    <Routes>
      {isAuthenticated ? (
        <Route element={<RouterLayout />} path="/">
          {routes.map(route => (
            <Route key={route.path} element={route.element} path={route.path} />
          ))}
        </Route>
      ) : (
        <Route element={<Login />} path="*" />
      )}
      <Route element={<ResetPassword />} path="/reset-password" />
    </Routes>
  );
}

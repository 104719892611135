interface Product {
  brand?: string;
  productType: {
    code?: string;
    description: string;
  };
}

export default function formatProductDescription(product: Product) {
  let retVal = product.productType.description;

  if (product.brand) retVal = `${product.brand} ${product.productType.description}`;
  if (product.productType.code) retVal = "(" + product.productType.code + ") " + retVal;

  return retVal;
}

import { useMemo } from "react";
import { gql, useQuery } from "urql";

export default function useRelationProfile() {
  const [{ data, fetching: loading }] = useQuery({ query: RelationQuery });

  const isDealer = data?.relation.isDealer;
  const locationMap = useMemo(() => {
    const locationsGroupedByRelation: Record<string, { name: string; locations: unknown[] }> = {};
    if (data === undefined) return locationsGroupedByRelation;

    const relation = data.relation;
    locationsGroupedByRelation[relation.id] = {
      name: relation.name,
      locations: relation.locations,
    };

    data.relation.subRelations.forEach(relation_ => {
      locationsGroupedByRelation[relation_.id] = {
        name: relation_.name,
        locations: relation_.locations,
      };
    });

    return locationsGroupedByRelation;
  }, [data]);

  const locationList = useMemo(() => {
    return Object.values(locationMap).flatMap(value => value.locations);
  }, [locationMap]);
  const relationList = useMemo(() => {
    if (data === undefined) return [];
    return [data.relation, ...data.relation.subRelations];
  }, [data]);

  return { loading, isDealer, relation: data?.relation, locationMap, locationList, relationList };
}

const RelationQuery = gql`
  query {
    relation {
      id
      name
      place
      afasCode
      isDealer
      subRelations {
        id
        name
        afasCode
        activeContract {
          id
          startingOn
          endingOn
        }
        locations {
          id
          name
          contactPerson
          primaryEmail
          secondaryEmail
          phoneNumber
          mobilePhoneNumber
          address {
            street
            postalCode
            city
            country
            coordinates {
              latitude
              longitude
            }
          }
        }
      }
      locations {
        id
        name
        contactPerson
        primaryEmail
        secondaryEmail
        phoneNumber
        mobilePhoneNumber
        address {
          street
          postalCode
          city
          country
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`;

import { FileExcelOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import * as React from "react";
import { useQuery } from "urql";

import DownloadButton from "@/components/DownloadButton";
import downloadAttachment from "@/functions/download-attachment";
import filterUnique from "@/functions/filter-unique";
import formatAddress from "@/functions/format-address";
import formatProductDescription from "@/functions/format-product-description";
import tableSorter from "@/functions/table-sorter";
import truncate from "@/functions/truncate";
import useRelationProfile from "@/hooks/use-relation-profile";

import ProductCondition from "./ProductCondition";
import ServicedProductsQuery from "./ServicedProductsQuery";
import ServicedProductsSummaryQuery from "./ServicedProductsSummaryQuery";
import ShowProductModal from "./ShowProductModal";

const STATUS_LIST = [
  { text: "Goed", value: "CONDITION_GOOD" },
  { text: "Actie vereist", value: "CONDITION_ACTION_REQUIRED" },
  { text: "Onbekend", value: "CONDITION_UNKNOWN" },
];

const DEFAULT_FILTERS = {
  relationId: undefined as string | undefined,
  locationId: undefined as string | undefined,
  productCategoryId: undefined as string | undefined,
  productTypeId: undefined as string | undefined,
  condition: ["CONDITION_GOOD", "CONDITION_ACTION_REQUIRED", "CONDITION_UNKNOWN"],
};

function collectRelations(relations: Array<{ id: number; afasCode: string; name: string }>) {
  const filterOptions = relations.map(r => ({
    value: r.id,
    text: `(${r.afasCode}) ${r.name}`,
  }));

  const uniqueOptions = filterUnique(filterOptions);

  return uniqueOptions.sort(tableSorter);
}

function collectLocations(locationMap: Record<string, { name: string; locations: unknown[] }>, relationId?: string) {
  // eslint-disable-next-line prettier/prettier
  const baseElements = relationId
    ? [locationMap[relationId]]
    : Object.values(locationMap)

  return baseElements.map(element => {
    return {
      text: element.name,
      value: element.name,
      children: element.locations.map(l => ({
        value: l.id,
        text: `${l.name} (${formatAddress(l.address as any)})`,
      })),
    };
  });
}

function collectUniqueProductCategories(productTypes: Array<{ category: { parent?: Record<string, unknown>; id: string; name: string } }>) {
  const categories = productTypes.map(pt => pt.category.parent ?? pt.category);
  const deduped = [...new Map(categories.map(c => [c.id, c])).values()];

  return deduped.map(v => ({ text: v.name, value: v.id })).sort(tableSorter);
}

function collectProductTypes(productTypes: Record<string, unknown>[]) {
  // eslint-disable-next-line prettier/prettier
  return productTypes
    .map(productType => ({ text: productType.description, value: productType.id }))
    .sort(tableSorter);
}

export default function Products() {
  const [{ offset, ...filters }, setSearchParams] = React.useState({ ...DEFAULT_FILTERS, offset: 0 });
  const [selectedProductId, setSelectedProductId] = React.useState<string | undefined>();

  const { isDealer, locationMap, relationList } = useRelationProfile();

  // eslint-disable-next-line prettier/prettier
  const [{ data: servicedProductsSummaryData }] = useQuery({
    query: ServicedProductsSummaryQuery,
    variables: { relationId: filters.relationId, locationId: filters.locationId, condition: filters.condition },
  });
  const productTypes = servicedProductsSummaryData?.servicedProductsSummary.map(d => d.productType) ?? [];

  // eslint-disable-next-line prettier/prettier
  const [{ data: servicedProductsData, fetching: loading }] = useQuery({
    query: ServicedProductsQuery,
    variables: { ...filters, offset: Number(offset) },
    pause: isDealer === undefined,
  });
  const products = servicedProductsData?.servicedProducts.edges ?? [];

  const columns: ColumnsType<any> = React.useMemo(() => {
    const baseColumns = [
      {
        title: "Locatie",
        dataIndex: "location",
        filters: collectLocations(locationMap, filters.relationId),
        filterMultiple: false,
        render: location => (
          <>
            <span style={{ display: "block" }}>{location.name}</span>
            <em style={{ display: "block", marginTop: 4 }}>{truncate(formatAddress(location.address), 75)}</em>
          </>
        ),
      },
      {
        title: "Ophanglocatie",
        dataIndex: "locationDescription",
      },
      {
        title: "Categorie",
        key: "productCategory",
        render: ({ productType }) => productType.category?.parent?.name ?? productType.category.name,
        filters: collectUniqueProductCategories(productTypes),
        filterMultiple: false,
      },
      {
        title: "Soort product",
        key: "productType",
        render: product => formatProductDescription(product),
        filters: collectProductTypes(productTypes),
        filterMultiple: false,
      },
      {
        title: "Laatst gecontroleerd",
        dataIndex: "lastCheckedOn",
        render: lastCheckedOn => dayjs(lastCheckedOn).format("DD/MM/YYYY"),
      },
      {
        title: "Status",
        dataIndex: "condition",
        filters: STATUS_LIST,
        render: condition => ProductCondition[condition as keyof typeof ProductCondition],
      },
    ];

    if (true === isDealer) {
      baseColumns.unshift({
        title: "Klant",
        key: "relation",
        dataIndex: ["location", "relation"],
        filters: collectRelations(relationList),
        filterMultiple: false,
        render: relation => `(${relation.afasCode}) ${relation.name}`,
      });
    }

    return baseColumns;
  }, [isDealer, products, productTypes, locationMap]);

  const handleOnDownloadExport = async () => {
    return downloadAttachment("reports/products-for-relation");
  };

  return (
    <>
      <Table
        bordered
        className="table-clickable-rows"
        columns={columns}
        dataSource={products}
        loading={loading}
        onChange={(pagination, filters) => {
          setSearchParams({
            relationId: (filters["relation"]?.[0] as string) ?? undefined,
            locationId: (filters["location"]?.[0] as string) ?? undefined,
            productCategoryId: (filters["productCategory"]?.[0] as string) ?? undefined,
            productTypeId: (filters["productType"]?.[0] as string) ?? undefined,
            condition: (filters["condition"] as string[]) ?? DEFAULT_FILTERS.condition,
            offset: ((pagination.current ?? 1) - 1) * PAGE_SIZE,
          });
        }}
        onRow={record => ({
          onClick: () => setSelectedProductId(record.id),
        })}
        title={() => (
          <DownloadButton onClick={handleOnDownloadExport} icon={<FileExcelOutlined />} type="primary">
            Exporteren
          </DownloadButton>
        )}
        pagination={{ total: servicedProductsData?.servicedProducts.totalCount ?? undefined, pageSize: PAGE_SIZE }}
        rowKey="id"
      />
      {undefined !== selectedProductId && (
        <ShowProductModal onClose={() => setSelectedProductId(undefined)} productId={selectedProductId} />
      )}
    </>
  );
}

const PAGE_SIZE = 10;

import { App, Button, Card, Form, Input } from "antd";
import { gql, useMutation } from "urql";

import useAuthState from "@/authorization/use-auth-state";

export default function UserSettings() {
  const { notification } = App.useApp();

  const [{ fetching: loading }, updateSettingsAsync] = useMutation(UpdatePasswordMutation);
  const { user } = useAuthState();

  const handleOnSubmit = async (values: Record<string, string>) => {
    try {
      await updateSettingsAsync({ plainPassword: values.password });
      notification.success({ message: "Uw wachtwoord is aangepast" });
    } catch (_error) {
      notification.error({ message: "Er ging iets mis. Probeer het later nog eens" });
    }
  };

  return (
    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", columnGap: 16, rowGap: 16 }}>
      <Card>
        <Form layout="vertical" onFinish={handleOnSubmit}>
          <Form.Item label="E-mail adres">
            <Input disabled value={user.email} />
          </Form.Item>
          <Form.Item label="Nieuw wachtwoord" name="password" required hasFeedback rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Bevestig nieuw wachtwoord"
            name="confirmPassword"
            required
            hasFeedback
            dependencies={["password"]}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) return Promise.resolve();
                  return Promise.reject(new Error("Wachtwoorden komen niet overeen"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Wijzigen
          </Button>
        </Form>
      </Card>

      <div />
    </div>
  );
}

const UpdatePasswordMutation = gql`
  mutation updatePassword($plainPassword: String!) {
    changeSettings(input: { plainPassword: $plainPassword }) {
      status
    }
  }
`;

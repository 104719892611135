import { PhoneOutlined } from "@ant-design/icons";
import * as React from "react";

export default function PhoneNumber({ children }: { children: React.ReactNode }) {
  return (
    <>
      <PhoneOutlined />
      <a href={`tel://${children}`} style={{ marginLeft: 4 }}>
        {children}
      </a>
    </>
  );
}

import { Button, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import * as React from "react";
import { gql, useQuery } from "urql";

import PhoneNumber from "@/components/PhoneNumber";
import filterUnique from "@/functions/filter-unique";
import formatAddress from "@/functions/format-address";
import tableSorter from "@/functions/table-sorter";
import truncate from "@/functions/truncate";
import useRelationProfile from "@/hooks/use-relation-profile";

import RegisterIssueModal from "./RegisterIssueModal";
import ShowIssueModal from "./ShowIssueModal";

const issueStatuses = {
  STATUS_RAISED: "Aangekaart",
  STATUS_PENDING: "In behandeling",
  STATUS_RESOLVED: "Opgelost",
};

function collectRelations(relations: unknown[]) {
  const filterOptions = relations.map(r => ({
    value: r.id,
    text: `(${r.afasCode}) ${r.name}`,
  }));

  const uniqueOptions = filterUnique(filterOptions);

  return uniqueOptions.sort(tableSorter);
}

function collectLocations(locationMap: Record<string, unknown>, relationId?: string) {
  // eslint-disable-next-line prettier/prettier
  const baseElements = relationId !== undefined
    ? [locationMap[relationId]]
    : Object.values(locationMap)

  return baseElements.map(element => {
    return {
      text: element.name,
      value: element.name,
      children: element.locations.map(l => ({
        value: l.id,
        text: `${l.name} (${formatAddress(l.address as any)})`,
      })),
    };
  });
}

export default function IssueTracker() {
  const { isDealer, locationMap, relationList } = useRelationProfile();

  const [{ data, fetching: loading }] = useQuery({ query: IssuesQuery });
  const [selectedIssueId, setSelectedIssueId] = React.useState<string | undefined>();
  const [raiseIssueModalVisible, setRaiseIssueModalVisible] = React.useState(false);

  const columns: ColumnsType<any> = React.useMemo(() => {
    const baseColumns = [
      {
        title: "Gemeld op",
        render: issue => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {issue.createdOn.format("DD/MM/YYYY")}
            <i style={{ fontSize: 10 }}>Laatste bericht: {dayjs(issue.lastCommentedAt).format("DD/MM/YYYY")}</i>
          </div>
        ),
        sorter: (a, b) => a.createdOn - b.createdOn,
        defaultSortOrder: "descend",
      },
      {
        title: "Locatie",
        dataIndex: "location",
        filters: collectLocations(locationMap, undefined),
        onFilter: (value, record) => record.location.id === value,
        filterSearch: true,
        render: location => (
          <>
            <span style={{ display: "block" }}>{location.name}</span>
            <em style={{ display: "block", marginTop: 4 }}>{truncate(formatAddress(location.address), 75)}</em>
          </>
        ),
      },
      { title: "Onderwerp", dataIndex: "subject" },
      { title: "Aangemaakt door", dataIndex: ["createdBy", "username"] },
      {
        title: "Status",
        dataIndex: "status",
        render: status => issueStatuses[status as keyof typeof issueStatuses],
        filters: Object.keys(issueStatuses).map(value => ({ value, text: issueStatuses[value as keyof typeof issueStatuses] })),
      },
    ];

    if (true === isDealer) {
      baseColumns.splice(1, 0, {
        title: "Klant",
        key: "relation",
        dataIndex: ["relation"],
        filters: collectRelations(relationList),
        filterMultiple: false,
        onFilter: (value, record) => record.relation.id === value,
        render: relation => `(${relation.afasCode}) ${relation.name}`,
      });
    }

    return baseColumns;
  }, [data, isDealer]);

  const preparedData = React.useMemo(
    () =>
      (data?.issues ?? []).map(issue => ({
        ...issue,
        createdOn: dayjs(issue.createdOn),
      })),
    [data]
  );

  return (
    <>
      <Table
        bordered
        columns={columns}
        className="table-clickable-rows"
        dataSource={preparedData}
        loading={loading}
        rowKey="id"
        onRow={record => ({
          onClick: () => setSelectedIssueId(record.id),
        })}
        title={() => (
          <div>
            <Button onClick={() => setRaiseIssueModalVisible(true)} type="primary">
              Nieuw bericht
            </Button>
            <Typography.Text style={{ marginLeft: 8 }}>
              Voor urgente problemen kunt u tijdens kantooruren bellen naar <PhoneNumber>050 311 6085</PhoneNumber>
            </Typography.Text>
          </div>
        )}
      />
      {raiseIssueModalVisible && <RegisterIssueModal onClose={() => setRaiseIssueModalVisible(false)} />}
      {undefined !== selectedIssueId && <ShowIssueModal onClose={() => setSelectedIssueId(undefined)} issueId={selectedIssueId} />}
    </>
  );
}

const IssuesQuery = gql`
  query IssuesQuery {
    issues {
      id
      relation {
        id
        afasCode
        name
      }
      location {
        id
        name
        address {
          street
          postalCode
          city
          country
        }
      }
      createdOn
      createdBy {
        username
      }
      subject
      lastCommentedAt
      status
    }
  }
`;

import "./index.css";

import * as Sentry from "@sentry/react";
import { App as AntApp } from "antd";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import AuthenticationContextProvider from "@/authorization/AuthenticationContextProvider";
import ConfigProvider from "@/components/ConfigProvider";
import Constants from "@/constants";
import createSentryDialogOptions from "@/functions/create-sentry-dialog-options";

import DefibrionApp from "./App";
import { ClientProvider } from "./urql/ClientProvider";

Sentry.init({
  enabled: Constants.environment === "prod",
  dsn: "https://1b084eb7ff8342bf9240266a58296817@o81216.ingest.sentry.io/4504158465556480",
  tracesSampleRate: 0.0,
});

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<span>Woops! Something went wrong</span>} showDialog dialogOptions={createSentryDialogOptions}>
      <AntApp>
        <ClientProvider>
          <AuthenticationContextProvider>
            <ConfigProvider>
              <BrowserRouter>
                <DefibrionApp />
              </BrowserRouter>
            </ConfigProvider>
          </AuthenticationContextProvider>
        </ClientProvider>
      </AntApp>
    </Sentry.ErrorBoundary>
  );
}

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(<App />);

import { FilePdfOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { gql, useMutation, useQuery } from "urql";

import filterUnique from "@/functions/filter-unique";
import formatAddress from "@/functions/format-address";
import tableSorter from "@/functions/table-sorter";
import truncate from "@/functions/truncate";
import useRelationProfile from "@/hooks/use-relation-profile";

const PAGE_SIZE = 10;

const DEFAULT_FILTERS = {
  relationId: null as string | null,
  locationIds: [] as Array<string>,
};

function collectRelations(relations: Array<{ id: number; afasCode: number; name: string }>) {
  const filterOptions = relations.map(r => ({
    value: r.id,
    text: `(${r.afasCode}) ${r.name}`,
  }));

  const uniqueOptions = filterUnique(filterOptions);

  return uniqueOptions.sort(tableSorter);
}

function collectLocations(locationMap: Record<string, unknown>, relationId: string | null) {
  // eslint-disable-next-line prettier/prettier
  const baseElements = relationId
    ? [locationMap[relationId]]
    : Object.values(locationMap)

  return baseElements.map(element => {
    return {
      text: element.name,
      value: element.name,
      children: element.locations.map(l => ({
        value: l.id,
        text: `${l.name} (${formatAddress(l.address as any)})`,
      })),
    };
  });
}

export default function ListAppointments() {
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const { isDealer, locationMap, relationList } = useRelationProfile();
  const [_, fetchFileAsync] = useMutation(FetchFileMutation);

  const [{ data, fetching: loading }] = useQuery({
    query: AppointmentsQuery,
    variables: {
      locationIds: filters.locationIds.length > 0 ? filters.locationIds : undefined,
      offset,
    },
  });

  const columns: ColumnsType<any> = useMemo(() => {
    const baseColumns: ColumnsType<any> = [
      {
        title: "Datum",
        dataIndex: ["startTime"],
        key: "date",
        render: startTime => startTime.format("DD/MM/YYYY"),
        sorter: (a, b) => a.startTime - b.startTime,
        defaultSortOrder: "descend",
      },
      {
        title: "Locatie",
        dataIndex: ["location"],
        filters: collectLocations(locationMap, filters.relationId),
        onFilter: (value, record) => record.location.id === value,
        filterSearch: true,
        render: location => (
          <>
            <span style={{ display: "block" }}>{location.name}</span>
            <em style={{ display: "block", marginTop: 4 }}>{truncate(formatAddress(location.address), 75)}</em>
          </>
        ),
      },
      {
        title: "Soort afspraak",
        dataIndex: ["appointmentType", "publicName"],
        render: publicName => publicName ?? "-",
      },
      {
        title: "Status",
        dataIndex: ["status"],
        render: status => (status === "STATUS_SCHEDULED" ? "Ingeroosterd" : "Bevestigd"),
        onFilter: (value, record) => record.status === value,
        filters: [
          { text: "Ingeroosterd", value: "STATUS_SCHEDULED" },
          { text: "Bevestigd", value: "STATUS_CONFIRMED" },
        ],
      },
      {
        title: "Service medewerker",
        dataIndex: ["employee", "username"],
      },
      {
        title: "Rapportages",
        dataIndex: ["files"],
        render: files => (files.length > 0 ? <Button icon={<FilePdfOutlined />}>Bekijk rapportage</Button> : "Nog geen rapportage"),
        width: 1, // minimizes width
      },
    ];

    if (true === isDealer) {
      baseColumns.splice(1, 0, {
        title: "Klant",
        key: "relation",
        dataIndex: ["location", "relation"],
        filters: collectRelations(relationList),
        filterMultiple: false,
        onFilter: (value, record) => record.location.relation.id === value,
        render: relation => `(${relation.afasCode}) ${relation.name}`,
      });
    }

    return baseColumns;
  }, [data, isDealer]);

  const preprocessedData = useMemo(
    () =>
      (data?.pastAndFutureAppointments.edges ?? []).map(appointment => ({
        ...appointment,
        startTime: dayjs(appointment.startTime),
      })),
    [data]
  );

  const handleOnClick = async (worksheetId: string | null) => {
    if (null === worksheetId) return;

    const worksheet = data?.pastAndFutureAppointments.edges.find(w => w.worksheetId === worksheetId);
    if (undefined === worksheet) return;

    const fileId = worksheet.files?.[0]?.id;
    if (undefined === fileId) return;

    const response = await fetchFileAsync({ input: { id: fileId } });
    const fileHref = response?.data?.fetchFileLink?.href;

    window.open(fileHref, "_blank");
  };

  return (
    <Table
      bordered
      className="table-clickable-rows"
      columns={columns}
      dataSource={preprocessedData}
      onChange={(pagination, filters) => {
        setOffset(((pagination.current ?? 1) - 1) * (pagination.pageSize ?? PAGE_SIZE));

        setFilters({
          relationId: (filters["relation"]?.[0] as string) || null,
          locationIds: (filters["location"] as string[]) ?? [],
        });
      }}
      onRow={record => ({ onClick: () => handleOnClick(record.worksheetId) })}
      loading={loading}
      rowKey={record => `${record.appointmentId}+${record.worksheetId}`}
      pagination={{ pageSize: PAGE_SIZE, total: data?.pastAndFutureAppointments.totalCount ?? 0 }}
    />
  );
}

const AppointmentsQuery = gql`
  query ($locationIds: [ID!], $offset: Int) {
    pastAndFutureAppointments(locationIds: $locationIds, offset: $offset) {
      totalCount
      edges {
        appointmentId
        worksheetId
        appointmentType {
          id
          publicName
        }
        location {
          id
          name
          relation {
            id
            afasCode
            name
          }
          address {
            street
            postalCode
            city
            country
          }
        }
        employee {
          username
        }
        startTime
        endTime
        status
        files {
          id
          name
        }
      }
    }
  }
`;

const FetchFileMutation = gql`
  mutation FetchFileMutation($input: FetchFileLinkInput!) {
    fetchFileLink(input: $input) {
      href
    }
  }
`;

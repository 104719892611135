import { Avatar } from "antd";
import * as React from "react";

interface GravatarProps {
  username: string;
  size?: number;
}

export default function MyAvatar({ username, size = 18 }: GravatarProps) {
  const initials = username.split(" ").map(word => word.charAt(0));
  const usable = [initials.at(0), initials.at(initials.length - 1)].filter(value => value !== undefined && value.length > 0);

  return <Avatar size={size}>{usable.join("")}</Avatar>;
}

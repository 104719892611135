import { gql } from "urql";

export default gql`
  query (
    $relationId: ID
    $locationId: ID
    $productCategoryId: ID
    $productTypeId: ID
    $serialCode: String
    $condition: [ProductCondition!]
    $offset: Int!
  ) {
    servicedProducts(
      relationId: $relationId
      locationId: $locationId
      productCategoryId: $productCategoryId
      productTypeId: $productTypeId
      serialCode: $serialCode
      condition: $condition
      offset: $offset
    ) {
      totalCount
      edges {
        id
        productType {
          id
          code
          description
          category {
            id
            name
            parent {
              id
              name
            }
          }
        }
        brand
        serialCode
        locationDescription
        location {
          id
          name
          relation {
            id
            name
            afasCode
          }
          address {
            street
            postalCode
            city
            country
          }
        }
        lastCheckedOn
        condition
      }
    }
  }
`;

interface InfoPropProps {
  label: string;
  value: React.ReactNode;
}

export default function InfoProp({ label, value }: InfoPropProps) {
  return (
    <div style={{ display: "flex", flexDirection: "column", marginBottom: 12 }}>
      <span style={{ fontWeight: "bold" }}>{label}</span>
      <span>{value}</span>
    </div>
  );
}

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Skeleton, Space, Typography } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import useAuthState from "@/authorization/use-auth-state";
import useQueryParams from "@/hooks/use-query-params";

import LogoImagePng from "../../../assets/logo-straight.png";
import Layout from "./Layout";

export default function ResetPassword() {
  const navigate = useNavigate();

  const confirmationToken = useQueryParams().get("token");
  if (!confirmationToken) throw new Error("Confirmation token not available");

  const { isFetching, checkPasswordResetToken, resetPassword, logoutUser } = useAuthState();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [username, setUsername] = React.useState<string | undefined>();

  React.useEffect(() => {
    async function main() {
      if (!confirmationToken) return;

      try {
        const _username = await checkPasswordResetToken(confirmationToken);
        setUsername(_username);
      } catch (error) {
        setErrorMessage((error as Error).message);
      }
    }

    main();
  }, []);

  const handleOnSubmit = async (values: Record<string, string>) => {
    if (!confirmationToken) return;

    try {
      await resetPassword(confirmationToken, values.password);
      logoutUser();

      navigate("/authenticate?password-reset=1");
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  return (
    <Layout>
      <Space direction="vertical" style={{ marginBottom: 20, width: "100%" }}>
        <img src={LogoImagePng} style={{ display: "block", margin: "auto", marginBottom: 8, width: 200 }} />
        <Typography.Title level={3} style={{ textAlign: "center" }}>
          Wachtwoord wijzigen
        </Typography.Title>

        {errorMessage && (
          <div style={{ marginBottom: 24 }}>
            <Alert message={errorMessage} type="error" />
          </div>
        )}

        {undefined === username ? (
          <Skeleton />
        ) : (
          <Form initialValues={{ username }} onFinish={handleOnSubmit} layout="vertical">
            <Form.Item name="username">
              <Input disabled readOnly placeholder="E-mail adres" prefix={<UserOutlined />} />
            </Form.Item>

            <Form.Item name="password">
              <Input.Password prefix={<LockOutlined />} placeholder="Wachtwoord" required />
            </Form.Item>

            <Form.Item
              name="repeatPassword"
              dependencies={["password"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) return Promise.resolve();
                    return Promise.reject(new Error("Wachtwoorden komen niet overeen"));
                  },
                }),
              ]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="Bevestig achtwoord" required />
            </Form.Item>

            <Button block loading={isFetching} htmlType="submit" type="primary">
              Nieuw wachtwoord instellen
            </Button>
          </Form>
        )}
      </Space>
    </Layout>
  );
}
